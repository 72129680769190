import { kebabCase, startCase } from 'lodash'

import { bind, downloadFileWithNameFromHeaders } from '../common/utils'
import { CompanyIO } from './serializers'
import { getPageNumber } from './'

export const logMasks = {
  msisdn: 1,
  message: 2,
}

const customerFilters = {
  name: 'name',
  vatCode: 'vatcode',
  externalId: 'extid',
  id: 'id',
  status: 'status',
  demoStatus: 'demo',
  info: 'info',
  created: 'created',
  billingInterval: 'billing_interval',
  paymentTerm: 'payment_term',
  download: 'download',
}

/**
 * Customer API
 */
export class Customer {
  constructor(api) {
    this.api = api
    bind(this, [
      'deleteConnection',
      'deleteBilling',
      'deleteCustomer',
      'getApplications',
      'getBillings',
      'getCustomer',
      'getCustomers',
      'getConnections',
      'getUsers',
      'getMoNumbers',
      'toggleApplication',
      'saveBilling',
      'saveConnection',
      'saveCustomer',
      'saveUser',
      'downloadCompanies',
    ])
  }

  async deleteConnection({ id, companyId }) {
    return await this.api
      .delete(`mgmt/companies/${companyId}/connections`, {}, true, {
        'X-Filter': id,
      })
      .then(() => ({
        success: true,
      }))
  }

  /**
   * Delete Billing
   */
  deleteBilling({ id, companyId }) {
    return this.api
      .delete(`mgmt/companies/${companyId}/billing`, {}, true, {
        'X-Filter': JSON.stringify({ id }),
      })
      .then(() => ({
        success: true,
      }))
  }

  /**
   * Delete customer
   */
  deleteCustomer({ id }) {
    return this.api.delete('mgmt/companies/' + id).then(() => {
      return {
        success: true,
      }
    })
  }

  getApplications({ id }) {
    return this.api
      .get(`mgmt/companies/${id}/applications`)
      .then(({ available_applications, all_applications }) => {
        return {
          active: available_applications.map((app) => ({
            id: app.id,
            tags: [app.channels],
            description: [app.short_description],
            title: app.name,
            icon: app.icon,
            name: kebabCase(app.name.replace(/&/g, 'and')),
          })),
          inactive: all_applications.map((app) => ({
            id: app.id,
            tags: [app.channels],
            description: [app.short_description],
            title: app.name,
            icon: app.icon,
            name: kebabCase(app.name.replace(/&/g, 'and')),
          })),
        }
      })
  }

  toggleApplication({ id, applicationId, action, successCallback }) {
    return this.api
      .post(`mgmt/companies/${id}/applications`, {
        applicationid: applicationId,
        action,
      })
      .then(() => {
        successCallback()
        return {
          success: true,
        }
      })
  }

  /**
   * Get single customer by ID
   */
  getCustomer({ id }) {
    return this.api.get('mgmt/companies/' + id).then(CompanyIO.in)
  }

  getConnections(options = {}) {
    const { company } = options.extra
    return this.api
      .get(
        `mgmt/companies/${company}/connections`,
        {},
        true,
        this.api.withFilters(options, {
          status: 'status',
          name: 'name',
          ctype: 'ctype',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results,
        }
      })
  }

  async saveConnection({
    id,
    name,
    ctype,
    status,
    setting: { systemid, password, throttle, maxsessions },
    companyId,
  }) {
    if (id) {
      return await this.api.put(`mgmt/companies/${companyId}/connections`, {
        id: parseInt(id),
        name,
        ctype,
        status,
        setting: {
          systemid,
          password,
          throttle,
          maxsessions,
        },
      })
    } else {
      return await this.api.post(`mgmt/companies/${companyId}/connections`, {
        name,
        ctype,
        status,
        setting: {
          systemid,
          password,
          throttle,
          maxsessions,
        },
      })
    }
  }

  /**
   * Get customers
   */
  getCustomers(options = {}) {
    return this.api
      .get(
        'mgmt/companies?page=' + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, customerFilters)
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map(CompanyIO.in),
        }
      })
  }

  getUsers(options = {}) {
    if (options.search.type === 'systemAdmin') {
      options.search.type = 'sysadmin'
    }
    const { company } = options.extra
    return this.api.get(
      `mgmt/companies/${company}/users?page=` + getPageNumber(options),
      {},
      true,
      this.api.withFilters(options, {
        username: 'username',
        email: 'email',
        first_name: 'first_name',
        last_name: 'last_name',
        is_active: 'is_active',
        assignment: 'assignment',
        msisdn: 'msisdn',
        type: 'type',
        date_joined: 'date_joined',
        last_login: 'last_login',
      })
    )
  }

  getMoNumbers(options = {}) {
    const { company } = options.extra
    return this.api
      .get(
        `mgmt/companies/${company}/monumbers?page=` + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          customer_id: 'customer_id',
          customer: 'customer',
          description: 'description',
          number: 'number',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.customer_id,
              number: result.number,
              customer: result.customer,
              description: result.description,
            }
          }),
        }
      })
  }

  getBillings(options = {}) {
    const { company } = options.extra
    options.limit = 100
    return this.api
      .get(
        `mgmt/companies/${company}/billing`,
        {},
        true,
        this.api.withFilters(options, {
          status: 'status',
          product: 'product__name',
          group: 'product__productgroup__name',
          billingType: 'product__billingtype',
          code: 'product__code',
          price: 'product__pricinginfo__price',
          vat: 'product__pricinginfo__price_vat',
          customPrice: 'customprice_info__price',
          customVat: 'customprice_info__vat',
          nextBillingDate: 'next_billing_date',
          description: 'description',
          begin: 'begin',
          end: 'end',
        })
      )
      .then((result) => {
        return {
          count: result.billings.length,
          results: result.billings.map((billing) => {
            return {
              id: billing.id,
              status: startCase(billing.status.toLowerCase()),
              productId: billing.product.id,
              product: billing.product,
              group: billing.product.productgroup.name,
              billingType: billing.product.billingtype.toLowerCase(),
              code: billing.product.code,
              price: billing.price,
              price_info: billing.price_info.map((price) => ({
                ...price,
                current: price.id === billing.price?.id,
              })),
              begin: billing.begin,
              end: billing.end,
              nextBillingDate: billing.next_billing_date,
              description: billing.description,
              currency: billing.product.currency,
              sender: billing.sender,
              destination: billing.destination,
            }
          }),
        }
      })
  }

  async saveBilling(companyId, values) {
    if (values.id) {
      return this.api.put(`mgmt/companies/${companyId}/billing`, values)
    } else {
      return this.api.post(`mgmt/companies/${companyId}/billing`, values)
    }
  }

  /**
   * Save customer
   */
  async saveCustomer(customer) {
    const payload = CompanyIO.out(customer)
    if (customer.id) {
      const result = await this.api.put(
        'mgmt/companies/' + customer.id,
        payload
      )
      return CompanyIO.in(result)
    } else {
      const result = await this.api.post('mgmt/companies', payload)
      return CompanyIO.in(result)
    }
  }

  saveUser({ user, company, request, successCallback }) {
    return this.api
      .put(`mgmt/companies/${company}/users`, {
        ...request,
        id: user,
      })
      .then(() => successCallback())
  }

  downloadCompanies({ filter, message }) {
    const {
      search: { download },
    } = filter
    return this.api
      .get(
        'mgmt/downloadcompanies',
        {},
        true,
        this.api.withFilters(filter, customerFilters),
        download === 'excel' ? true : false,
        true
      )
      .then((result) => {
        downloadFileWithNameFromHeaders({ result, message })
      })
  }
}
